
import { Vue, Component, Prop, } from 'vue-property-decorator'
import { FileUploadInterface, } from '@/store/modules/dataroom/dataroomModule'
import ConfirmDeleteDialog from '@/components/ConfirmDeleteDialog/Index.vue'

@Component({
  components: {
    ConfirmDeleteDialog,
  },
  computed: {},
})
export default class UploadFile extends Vue {
  @Prop({ required: false, }) readonly files!: any

  private documents = []

  private datarooms: string[] = []
  private dataroomsName: string[] = []
  private fileToUpload: FileUploadInterface[] = []

  private showDialogConfirmDelete = false

  private typeDelete = 0
  private elementDelete: any = {}

  private deleteConfirm (value: number, element: any) {
    this.typeDelete = value
    this.elementDelete = element
    this.showDialogConfirmDelete = true
  }

  private goDelete () {
    if (this.typeDelete == 0) {
      this.deleteFile(this.elementDelete)
    } else {
      this.requestConfirmDelete(this.elementDelete)
    }
  }

  private requestConfirmDelete (index: number) {
    this.fileToUpload.splice(index, 1)
  }

  private donwloadFile (element: any) {
    window.open(element.url_download, '_blank')
  }

  deleteFile (element: any) {
    const data = {
      act_id: this.$route.params.task_id,
      file_id: element.id,
    }

    this.$store.dispatch('SteppersModule/deleteFilesDocuments', data).then(() => {
      this.$emit('refreshAPI')
    })
  }

  private handleFiles () {
    const refFiles: any = this.$refs.myFiles
    const reader = new FileReader()
    reader.readAsDataURL(refFiles.files[0])
    reader.onload = () => {
      const nameFile: string = refFiles.files[0].name.toLowerCase()
      const sizeFile: number = refFiles.files[0].size
      const type: string = refFiles.files[0].type
      const extension: string = refFiles.files[0].type.split('/')[1]
      const maxFileSize = 25
      const maxSize =
        Number(process.env.VUE_APP_MAX_BATCH_SIZE_IN_MB) * 1024 * 1024 || maxFileSize * 1024 * 1024

      if (sizeFile > maxSize) {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message: `Error, el tamaño no puede ser mayor a ${maxFileSize}mb.`,
          color_notification: 'danger',
        })
        return
      }

      if (
        nameFile.split('.').pop() === 'pdf' ||
        nameFile.split('.').pop() === 'csv' ||
        nameFile.split('.').pop() === 'xls' ||
        nameFile.split('.').pop() === 'xlsx' ||
        nameFile.split('.').pop() === 'jpg' ||
        nameFile.split('.').pop() === 'jpeg' ||
        nameFile.split('.').pop() === 'png'
      ) {
        // const readerResult: string | undefined = reader?.result?.toString()

        this.fileToUpload.push({
          filename: nameFile,
          dataFormData: refFiles.files[0],
          size: sizeFile.toString(),
          name: nameFile,
          type: type,
          extension: extension,
          // new_file: this.documents ? false : true,
        })

        this.$emit('refreshDocuments', this.fileToUpload)
        this.$emit('newFile', this.documents.length > 0 ? false : true)

        // const readerResult: string | undefined = reader?.result?.toString()
      } else {
        this.$store.dispatch('NotificactionsModule/OpenNotification', {
          message:
            'Error, Solo permitimos este tipos de archivo: .pdf, .xls, .xlsx, .csv, .png, .jpg, .jpeg',
          color_notification: 'danger',
        })
        return
      }
    }
  }
}
