
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator'
import { SelectInterface, } from '@/utils/Select'

@Component({})
export default class SelectFrecuency extends Vue {
  @Prop({ required: false, default: 'Revisión cada 1 año', }) name!: string
  @Prop({ required: false, default: false, }) readonly selected!: string
  @Prop({ required: false, }) readonly items!: SelectInterface
  private value = 'one_year'

  mounted () {
    if (this.selected) {
      this.value = this.selected
    } 
  }

  @Watch('value')
  valueChage (): void {
    this.$emit('value', this.value)
  }

}
