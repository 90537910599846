
import { Component, Vue, Watch, Prop, } from 'vue-property-decorator'

@Component({})
export default class ConfirmDeleteDialog extends Vue {
  @Prop({ required: true, }) readonly showDialog!: boolean
  private dialog = false

  @Watch('showDialog')
  show (val: boolean): void {
    if (val) {
      this.dialog = true
    }
  }

  @Watch('dialog')
  dialogClose (): void {
    if (this.dialog === false) {
      this.$emit('close')
    }
  }

  private deleteFileLocal () {
    this.dialog = false
    this.$emit('action')
  }
}
